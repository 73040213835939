/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  initialRouteName: 'Sign_In',
  config: {
    screens: {
      Sign_In: 'login-screen',
      login: '/login',
      RegisterScreen: '/RegisterScreen',
      Sign_Up: 'register-screen',
      Password_Reset: 'forgot-password-screen',
      Change_Password: 'reset-password-screen',
      Home: 'home',
      Properties: 'properties',
      PropertyAddress: 'propertyAddress',
      PropertySection: 'propertySection',
      Tenants: 'tenants',
      News: 'News',
      Setting: 'Setting',
      ChangePassword: 'ChangePassword',
      TenantArchive: 'TenantArchive',
      PropertyArchive: 'PropertyArchive',
      MyAccount: 'MyAccount',
      ContactUs: 'contact-us',
      AboutUs: 'about-us',
      TenantDeposit: 'TenantDeposit',
      TenantReminder: 'TenantReminder',
      PaymentIncomingDetails: 'payments/incoming/new',
      PaymentOutgoingDetails: 'payments/outgoing/new',
      PaymentNewProperty: 'payments/incoming/new/:property_id',
      EditReminder: 'Reminder/Edit/:id',
      AddReminder: 'Reminder/New',
      Reminder: 'Reminder',
      AccountEdit: 'Account/Edit',
      PropertyDetailsMore: 'PropertyDetails/:id',
      PropertyInfo: '/PropertyInfo/:id',
      TeanantInfo: '/TeanantInfo/:id',
      financeDetails: "/financeDetails/:id",
      TenantDetails: '/tenants/TenantDetails',
      PropertyTenantDetails: '/property/TenantDetails/:id',
      PropertiesInfoDetails:'/PropertiesInfoDetails/:id',
      PropertyDocumentation: '/tenants/PropertyDocumentation',
      RentalOffers: '/tenants/RentalOffers',
      TeanantOnboarding: '/tenants/TeanantOnboarding',
      TeanantOffboarding: '/tenants/TeanantOffboarding',
      HoldingDeposit: '/tenants/HoldingDeposit',
      IdAndReference: '/tenants/IdAndReference',
      Certification:"/Certification/:id",
      TenancyAgreement: '/tenants/TenancyAgreement',
      MoveIn: '/tenants/MoveIn',
      DepositProtectHomeSetup: '/tenants/DepositProtectHomeSetup',
      RentCollectionSignUp: '/tenants/RentCollectionSignUp',
      MediaHub: 'media-hub',
      'MediaHub Details': 'media-hub/:data',
      MediaVideoDetails:'/MediaVideoDetails',
      DocumentArchive: 'DocumentArchive',
      MyDocs: 'MyDocs',
      LegalBits:'/legal-bits',
      Charity:'/charity',
      hello:'hello',
      CommonBuyCertificate:"/certificate/buy",
      Notice:'tenants/TeanantOffboarding/Notice',
      financeInsuranceQuote:'/financeInsuranceQuote'
    },
  },
};
