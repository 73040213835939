/**
 * If you are not familiar with React Navigation, check out the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import { ColorSchemeName, View } from 'react-native';
import { RootStackParamList } from '../../types';
import LinkingConfiguration from './LinkingConfiguration';
import Tenants from '../screens/tenants';
import Dashboard from '../screens/dashboard/Dashboard';
import LoginScreen from '../screens/login/login';
import RegisterScreen from '../screens/register/register';
import Home from '../screens/home';
import ForgotPasswordScreen from '../screens/forgotPassword/forgotPassword';
import ResetPasswordScreen from '../screens/resetPassword/resetPassword';
import Settings from '../screens/settings';
import MyAccount from '../screens/myAccount';
import ChangePassword from '../screens/changePassword';
import PropertyArchive from '../screens/propertyArchive';
import TenantArchive from '../screens/tenantArchive';
import News from '../screens/news';
import Properties from '../screens/properties';
import PropertiesInfoDetails from '../screens/properties/PropertiesInfoDetails'
import PropertyAddress from '../screens/properties/propertyAddress';
import PropertySection from '../screens/properties/propertySection';
import Utilities from '../screens/properties/utilities';
import TenantScreen from '../screens/tenantsScreen';
import TenantReminder from '../screens/Reminder/AddReminder';
import PropertyDetails from '../screens/properties/propertyDetails';
import Certifications from '../screens/properties/certification';
import Licensing from '../screens/properties/licensing';
import Requirements from '../screens/properties/requirements';
import { navigate, navigationRef } from './RootNavigation';
import { useSelector } from 'react-redux';
import ajax, { httpUpload, setAuthHeaders } from '../../src/helpers/ajaxHelper';
import Loader from '../components/loader';
import payment from '../screens/payment';
import paymentDetails from '../screens/payment/paymentDetails';

import AddReminder from '../screens/Reminder/AddReminder';
import Reminder from '../screens/Reminder';
import AccountEdit from '../screens/settings/AccountEdit';
import PropertyDetailsReadOnly from '../screens/properties/propertyDetailsReadOnly';
import { WSnackBar } from './../vendor/react-native-smart-tip';
import TeanantDetails from '../screens/tenants/tenantOnboarding/screens/teanantDetails';
import PropertyDocumentation from '../screens/tenants/tenantOnboarding/screens/propertyDocumentation';
import RentalOffers from '../screens/tenants/tenantOnboarding/screens/rentalOffers';
import propertyInfo from '../screens/properties/propertyInfo';
import tenantInfo from '../screens/tenants/tenantInfo';
import TeanantOnboarding from '../screens/tenants/tenantOnboarding/screens/DashboardScreen';
import HoldingDeposit from '../screens/tenants/tenantOnboarding/screens/holdingDeposit';
import TenantIdentification from '../screens/tenants/tenantOnboarding/screens/TenantIdentification';
import TenancyInfoAndAgreement from '../screens/tenants/tenantOnboarding/screens/tenancyInfoAndAgreement';
import MovingIn from '../screens/tenants/tenantOnboarding/screens/movingIn';
import DepositProtectHomeSetup from '../screens/tenants/tenantOnboarding/screens/depositProtect_homeSetup';
import RentCollectionSignUp from '../screens/tenants/tenantOnboarding/screens/RentCollectionSignUp';
import MediaHub from '../screens/mediaHub';
import MediaHubDetails from '../screens/mediaHub/mediaHubDetails';
import DocumnetArchive from '../screens/documnetArchive';
import contactusScreen from '../screens/contactusScreen';
import aboutUs from '../screens/aboutUs';
import finance from '../screens/properties/finance';
import MyDocs from '../screens/MyDocs';
import Charity from '../screens/charity';
import legalScreen from '../screens/legalScreen';
import LandlordDetails from '../screens/properties/LandlordDetails';
import propertyTenantDetails from '../screens/tenants/tenantOnboarding/screens/propertyTenantDetails';
import { Hello } from '../screens/hello/hello';
import LandlordMediaDetails from '../screens/mediaHub/landlordDetails';
import MediaVideoDetails from '../screens/mediaHub/mediaVideoDetails';
import CommanBuyCertificate from '../screens/tenants/tenantOnboarding/screens/commanBuyCertificate/commonBuyCertificate';
import TeanantOffboarding from '../screens/tenants/tenantOffbaording/index'
import getPlatformType from '../helpers/helpers';
import Customise from '../screens/cookies/Customise';
import Cookies from '../screens/cookies/Cookies';
import store from '../redux/store';
import ProvidingNotice from '../screens/tenants/tenantOffbaording/offboardingScreen/ProvidingNotice';
import financeInsuranceQuote from '../screens/properties/financeInsuranceQuote';
export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  const routeNameRef = React.useRef();
  const layoutType = getPlatformType();
  const isToken = useSelector((state) => state?.AppReducer?.token);
  setAuthHeaders(isToken);
  const [cookiesPopupVisible, setCookiesPopupVisible] = useState(false);
  const show_customise = useSelector((state) => state?.cookiesReducer?.show_banner_customise);
  const show_cookies = useSelector((state) => state?.cookiesReducer?.show_banner_cookies);


  useEffect(() => {
    const cookiesBanner = localStorage.getItem('cookies_status');
    if (cookiesBanner) {
      setCookiesPopupVisible(false);
    } else {
      setTimeout(() => {
        setCookiesPopupVisible(true);
      }, 4000);
    }
  }, []);
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      backBehavior="history"
      ref={navigationRef}
      onReady={() => (routeNameRef.current = navigationRef.current.getCurrentRoute().name)}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
    >
      <>
     <RootNavigator />
        {show_customise ?
          <View
            style={{
              width: '100%',
              marginTop: layoutType == 'phone' ? '18%' : '7%',
              position: 'absolute',
            }}
          >
            <Customise />
          </View>
          : null}
        {cookiesPopupVisible && show_cookies && (

          <View
            style={{
              width: '100%',
              paddingTop: layoutType == 'phone' ? '18%' : '7%',
              position: 'absolute',
              height: '100%',
            }}
          >
            <Cookies
              setCookiesPopupVisible={setCookiesPopupVisible}
              cookiesPopupVisible={cookiesPopupVisible}
            />
          </View>
        )}
      </>
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
  const isToken = useSelector((state) => state?.AppReducer?.token);
  const [isLoading, setIsLoading] = useState(false);

  const setTokenInheader = async () => {
    await setAuthHeaders(isToken);
  }
  useEffect(() => {
    setTokenInheader()
  }, [isToken]);
  ajax.interceptors.request.use(
    function (config) {
      // spinning start to show

      // console.log('api requested', config);
      setIsLoading(true);
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  ajax.interceptors.response.use(
    function (response) {
      // console.log('api response hitesh', response);
      // spinning hide
      setIsLoading(false);
      if (response?.data?.message) {
        const snackBarOpts = {
          data: response?.data?.message ? response?.data?.message : 'Something went wrong!',
          position: WSnackBar.position.TOP, // 1.TOP 2.CENTER 3.BOTTOM
          duration: WSnackBar.duration.LONG, //1.SHORT 2.LONG 3.INDEFINITE
          textColor: 'white',
          backgroundColor: 'red',
          actionText: '',
          actionTextColor: '#ff490b',
          onActionHide: (isSlideHide) => {
            // Click Action
          },
        };
        WSnackBar.show(snackBarOpts);
      }
      return response;
    },

    function (error) {
      // alert('Server Error');
      console.log('errrrrrrr on handle', error);
      navigate("login")
      setIsLoading(false);
      return Promise.reject(error);
    }
  );
  httpUpload.interceptors.request.use(
    function (config) {
      // spinning start to show
      // console.log('api requested', config);
      setIsLoading(true);
      return config;
    },
    function (error) {
      setIsLoading(false);
      return Promise.reject(error);
    }
  );

  httpUpload.interceptors.response.use(
    function (response) {
      // spinning hide
      console.log('api response', response);
      setIsLoading(false);
      return response;
    },
    function (error) {
      setIsLoading(false);
      // alert('Server Error');
      return Promise.reject(error);
    }
  );
  return (
    <>
      <Stack.Navigator
        initialRouteName={isToken ? 'home' : 'Hello'}
        screenOptions={{ headerShown: false }}
        backBehavior="history"
      >
        <Stack.Screen name="home" component={Home} />
        <Stack.Screen name="Hello" component={Hello} />
        <Stack.Screen name="AccountEdit" component={AccountEdit} />
        <Stack.Screen name="tenants" component={Tenants} />
        <Stack.Screen name="TeanantInfo" component={tenantInfo} />
        <Stack.Screen name="TeanantOnboarding" component={TeanantOnboarding} />
        <Stack.Screen name="Utilities" component={Utilities} />
        <Stack.Screen name="PropertiesInfoDetails" component={PropertiesInfoDetails} />
        <Stack.Screen name="Reminder" component={Reminder} />

        <Stack.Screen name="AddReminder" component={AddReminder} />
        <Stack.Screen name="EditReminder" component={AddReminder} />
        <Stack.Screen name="login" component={LoginScreen} />

        <Stack.Screen name="Payments" component={payment} />
        <Stack.Screen name="PaymentDetails" component={paymentDetails} />
        <Stack.Screen name="PaymentIncomingDetails" component={paymentDetails} />
        <Stack.Screen name="PaymentOutgoingDetails" component={paymentDetails} />
        <Stack.Screen name="PaymentNewProperty" component={paymentDetails} />

        <Stack.Screen name="RegisterScreen" component={RegisterScreen} />
        <Stack.Screen name="ForgotPasswordScreen" component={ForgotPasswordScreen} />
        <Stack.Screen name="ResetPasswordScreen" component={ResetPasswordScreen} />
        <Stack.Screen name="News" component={News} />
        <Stack.Screen name="Root" component={Dashboard} />
        <Stack.Screen name="Setting" component={Settings} />
        <Stack.Screen name="ChangePassword" component={ChangePassword} />
        <Stack.Screen name="TenantArchive" component={TenantArchive} />
        <Stack.Screen name="PropertyArchive" component={PropertyArchive} />
        <Stack.Screen name="MyAccount" component={MyAccount} />
        {/* <Stack.Screen name="tenants" component={Tenants} /> */}
        <Stack.Screen name="TenantDeposit" component={TenantScreen} />

        <Stack.Screen name="PropertyDetails" component={PropertyDetails} />
        <Stack.Screen name="PropertyInfo" component={propertyInfo} />
        <Stack.Screen name="PropertySection" component={PropertySection} />
        <Stack.Screen name="PropertyAddress" component={PropertyAddress} />
        <Stack.Screen name="Properties" component={Properties} />
        <Stack.Screen name="Certification" component={Certifications} />
        <Stack.Screen name="Licensing" component={Licensing} />
        <Stack.Screen name="Requirements" component={Requirements} />
        <Stack.Screen name="PropertyDetailsMore" component={PropertyDetailsReadOnly} />
        <Stack.Screen name="PropertyDocumentation" component={PropertyDocumentation} />
        <Stack.Screen name="TenantDetails" component={TeanantDetails} />
        <Stack.Screen name="PropertyTenantDetails" component={propertyTenantDetails} />
        <Stack.Screen name="RentalOffers" component={RentalOffers} />
        <Stack.Screen name="HoldingDeposit" component={HoldingDeposit} />
        <Stack.Screen name="IdAndReference" component={TenantIdentification} />
        <Stack.Screen name="TenancyAgreement" component={TenancyInfoAndAgreement} />
        <Stack.Screen name="MoveIn" component={MovingIn} />
        <Stack.Screen name="DepositProtectHomeSetup" component={DepositProtectHomeSetup} />
        <Stack.Screen name="ContactUs" component={contactusScreen} />
        <Stack.Screen name="MediaHub" component={MediaHub} />
        <Stack.Screen name="MediaHub Details" component={MediaHubDetails} />
        <Stack.Screen name="DocumentArchive" component={DocumnetArchive} />
        <Stack.Screen name="RentCollectionSignUp" component={RentCollectionSignUp} />
        <Stack.Screen name="AboutUs" component={aboutUs} />
        <Stack.Screen name="financeDetails" component={finance} />
        <Stack.Screen name="MyDocs" component={MyDocs} />
        <Stack.Screen name="LegalBits" component={legalScreen} />
        <Stack.Screen name="Charity" component={Charity} />
        <Stack.Screen name="LandlordDetails" component={LandlordDetails} />
        <Stack.Screen name="LandlordMediaDetails" component={LandlordMediaDetails} />
        <Stack.Screen name="MediaVideoDetails" component={MediaVideoDetails} />
        <Stack.Screen name="CommonBuyCertificate" component={CommanBuyCertificate} />
        <Stack.Screen name="TeanantOffboarding" component={TeanantOffboarding} />
        <Stack.Screen name="Notice" component={ProvidingNotice} />
        <Stack.Screen name="financeInsuranceQuote" component={financeInsuranceQuote} />
        
      </Stack.Navigator>
      <Loader loading={isLoading} />
    </>
  );
}
