import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogButton from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import ToolTip from '../tenantOnboarding/atoms/ToolTip';
import { Button } from '../tenantOnboarding/atoms/button';
import BackComponent from '../tenantOnboarding/atoms/backComponent';

import { CardBox } from '../tenantOnboarding/atoms/card/index';
import { ListItem } from '../tenantOnboarding/atoms/listitem';
import { ListCard } from '../tenantOnboarding/atoms/listCard';
import variables from '../tenantOnboarding/atoms/variables.json';

import HomeSetup from './../../../assets/svgIcons/OpenFlo/DepositProtect.svg';
import Movein from './../../../assets/svgIcons/OpenFlo/MoveIn.svg';
import Aggrement from './../../../assets/svgIcons/OpenFlo/Agreement.svg';
import ID_ICON from './../../../assets/svgIcons/OpenFlo/ID&Reference.svg';
import HOLDING_ICON from './../../../assets/svgIcons/OpenFlo/HoldingDeposit.svg';
import RENTAL_ICON from './../../../assets/svgIcons/OpenFlo/RentalOffer.svg';
import DOC_ICON from './../../../assets/svgIcons/OpenFlo/Documentation.svg';
import TEANANT_ICON from './../../../assets/svgIcons/OpenFlo/Tenant.svg';

import { CardWrapper, SubHeading, TlyfeButtonWrapper, TouchableArea } from '../tenantOnboarding/screens/style';
import drawerHoc from '../../../../src/components/drawerHOC/DrawerHOC';
import { navigate } from '../../../../src/navigation/RootNavigation';
import PageHeader from '../../../components/pageHeader';
import OffboardHeader from './header';

const TeanantOffboarding = (props) => {
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state?.tenant);
  const [flowId, setFlowId] = React.useState(null);
  const [flowIdInfo, setFlowIdInfo] = React.useState(2);

  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const [showWarningDialog, setShowWarningDialog] = React.useState(false);

  const closeDialog = () => {
    setShowConfirmDialog(false);
  };

  const closeWarningDialog = () => {
    setShowWarningDialog(false);
  };

  const addFlowToProperty = () => {
    // dispatch(tenantActions.addFlowToProperty(globalState?.flowIdInfo?.openflow_id));
    closeDialog();
  };

  const openDialog = () => {
    let allCompleted = ListArray.every(function (item) {
      return flowIdInfo[item.buttonStatus] == 1;
    });
    if (allCompleted) {
      setShowConfirmDialog(true);
    } else {
      setShowWarningDialog(true);
    }
  };

  useEffect(() => {
    if (props?.match?.params) {
      if (props?.match?.params?.id && props?.match?.params?.id != 'new') {
        // dispatch(tenantActions.getFlowInfo(props?.match?.params?.id));
      } else {
        // dispatch(tenantActions.cleanGetFlowInfo());
      }
    }
  }, []);

  useEffect(() => {
    if (globalState?.flowIdInfo?.openflow_id) {
      // setFlowId(globalState?.flowIdInfo?.openflow_id);
      // setFlowIdInfo(globalState?.flowIdInfo);
    }
  }, [globalState]);

  const ListArray = [
    {
      title: 'Providing Notice',
      buttonStatus: 'tenancy_status',
      routeKey: 'Notice',
      desc: `You need to provide a minimum of 2 months notice before the date you intend the tenant to leave. Below are is the earliest date of notice and exit for the tenant, if you would like to extend these dates please edit the fields.`,
      icon: TEANANT_ICON,
    },
 
    {
      title: 'Organising Property Viewings',
      buttonStatus: 'rental_offer_status',
      routeKey: 'RentalOffers',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nod. ',
      icon: RENTAL_ICON,
    },
    {
      title: 'Ensuring rent is up to date',
      buttonStatus: 'holding_deposit_status',
      routeKey: 'HoldingDeposit',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nod. ',
      icon: HOLDING_ICON,
    },
    {
      title: 'Final Inspection ',
      buttonStatus: 'verification_status',
      routeKey: 'IdAndReference',
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nod.",
      icon: ID_ICON,
    },
    {
      title: 'Deposit',
      buttonStatus: 'landlord_status',
      routeKey: 'TenancyAgreement',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nod.',
      icon: Aggrement,
    },
    
  ];
  const getLeadTenant = () => {
    let tenant = flowIdInfo.tenants;
    var leadTenant = tenant.filter(function (e) {
      return e.is_lead == true;
    });
    return leadTenant[0]?.app_user?.user;
  };
  //       this.props.history.push("/dashboard/partnership");
  return (
    <>
      <div>
        <Dialog
          open={showConfirmDialog}
          onClose={closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"> Move to Property? </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to complete this onboard?
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              If you select "YES", the flow will be moved to the "completed onboards" section
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <DialogButton onClick={addFlowToProperty} color="primary" autoFocus>
              Yes
            </DialogButton>
            <DialogButton onClick={closeDialog} color="primary">
              No
            </DialogButton>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog
          open={showWarningDialog}
          onClose={closeWarningDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"> Incomplete flow </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please complete all flow steps first to add it to the property
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <DialogButton onClick={closeWarningDialog} color="primary">
              Ok
            </DialogButton>
          </DialogActions>
        </Dialog>
      </div>

      {flowIdInfo ? (
        <CardWrapper>
          {flowIdInfo?.openflow_id && (
            <ListCard
              Id={flowId}
              showPropertyCard
              key={flowIdInfo.openflow_id}
              name={flowIdInfo?.tenants[0] ? getLeadTenant()?.first_name + ' ' + getLeadTenant()?.last_name : null}
              // name={
              //   flowIdInfo?.tenants[0]?.app_user?.user?.first_name
              //     ? flowIdInfo?.tenants[0]?.app_user?.user?.first_name +
              //       flowIdInfo?.tenants[0]?.app_user?.user?.last_name
              //     : null
              // }
              contact={flowIdInfo?.tenants ? getLeadTenant()?.contact_number : null}
              email={flowIdInfo?.tenants ? getLeadTenant()?.email : null}
              percentage={flowIdInfo?.percentage_completion ? (flowIdInfo?.percentage_completion).toFixed(1) : 0}
              propertyName={flowIdInfo?.property?.postcode}
              style={{ marginBottom: '5%', position: 'relative' }}
              leftComponent={() => {
                return <p></p>;
              }}
            />
          )}

<OffboardHeader
        property_name={"Ahmed"}
        property_worth={" N/A"}
        // id={state?.id}
        // percentage={state?.percentage}
        address1={"acron house"}
        city={"london"}
        postcode={"e140bt"}
      />

          <CardBox style={{ position: 'relative', width: '100%' , marginTop:40 }} backColor={variables.powderBlue}>
            <SubHeading color={variables.scooter}>
              OpenFlow is the tenant onboarding system that interacts with your tenant via thier own tlyfe app.
            </SubHeading>
            <SubHeading color={variables.scooter}>
              You can go through the process steps, in any order you wish. The system will guide you through in a
              compliant way.
            </SubHeading>
          </CardBox>

          <BackComponent
            hideMark
            hideLine
            handleClick={
              () => {}
              // props.history.push(`/dashboard/TenantLandingPage`)
            }
          />
          <View style={{ position: 'relative' }}>
            {/* <ToolTip
              style={{ bottom: '90%', right: '-40%' }}
              heading={'Tenant Tips'}
              ContentData={[
                'Tip 1 - Ensure you have the correct email address for the tenant, everything else the tenant can complete.',
                'Tip 2 -  Ask the applicants to Check to see if all the details  are entered correctly on the Tlyfe app. ',
              ]}
            /> */}
                 <ToolTip
              style={{ bottom: '90%', right: '-40%' }}
              heading={'Tool Tips'}
              ContentData={['Home Setup - This is where the OpenFlo will earn your agency referral commissions.']}
            />
            {ListArray.map((data, index) => (
              <TouchableArea
                key={index}
                onClick={() => {
                  if (data?.routeKey) {
                    navigate(data?.routeKey);
                  }
                }}
              >
                <ListItem
                  type={
                    flowIdInfo && flowIdInfo[data?.buttonStatus] == 0
                      ? 'incomplete'
                      : flowIdInfo[data?.buttonStatus] == 1
                      ? 'complete'
                      : 'in progress'
                  }
                  title={data.title}
                  showArrowIcon
                  showButton
                  key={index}
                  marginTop={'5%'}
                  description={data.desc}
                  icon={data.icon}
                />
              </TouchableArea>
            ))}
          </View>

          <TlyfeButtonWrapper style={{ marginTop: '5%', position: 'relative' }}>
       
            <Button
              title={'Offboard Tenant'}
              onClick={() => {
                openDialog();
              }}
              type="solid"
            />
          </TlyfeButtonWrapper>
        </CardWrapper>
      ) : null}
    </>
  );
};
export default drawerHoc({
  Component: TeanantOffboarding,
  showBackButton: true,
});
