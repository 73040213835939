import React, { useState, useEffect } from 'react';


// import Urls from '../../services/Urls';
import { useRoute } from '@react-navigation/native';
// import ajax from '../../helpers/ajaxHelper';
import drawerHoc from '../../../../../src/components/drawerHOC/DrawerHOC';

import DropDownPicker from '../../../../../src/components/picker/DropDownPicker';
import Box from '../../../../../src/components/box';
import FilePicker from '../../../../../src/components/filePicker/filepicker.web';
import DocsComponent from '../../../../../src/components/docsComponent';

import Text from '../../../../../src/components/text';
import { View } from 'react-native';
import PopUp from '../../../../../src/components/popUp/PopupView';
import TextInputComponent from '../../../../../src/components/textinput/TextInputComponent';
import getPlatformType from '../../../../helpers/helpers';
import { CardBox } from '../../tenantOnboarding/atoms/card';
import { SubHeading } from '../../tenantOnboarding/screens/style';
import variables from '../../tenantOnboarding/atoms/variables.json'

const layoutType = getPlatformType();
const ProvidingNotice = () => {
  const route = useRoute();
//   const [state, setState] = useState({
//     property_id: '',
//     file_name: '',
//     selected_files: [],
//   });
  const [properties, setProperties] = useState([]);




 

  const _handleChange = (e) => {
    setState({ ...state, [e.label]: e.value });
  };






  return (
    <>
   
      <Text fontSize={18} bold style={{ marginTop: 30 }}>
        Property access notice
      </Text>


      <CardBox style={{ position: 'relative', width: '100%' , marginTop:40 }} backColor={variables.powderBlue}>
            <SubHeading color={variables.scooter}>
              OpenFlow is the tenant onboarding system that interacts with your tenant via thier own tlyfe app.
            </SubHeading>
      
          </CardBox>
    
      
          <Box style={{marginTop:20}}>
      
              <TextInputComponent
                      onChangeText={(e) => {}}
                      value={{}}
                      title={'Reason for access'}
          />
            <View style={{ alignItems: 'center', flexDirection: layoutType == 'phone' ? 'column' : 'row', flex: 1 ,justifyContent: 'space-between' ,alignItems : 'flex-start' }}>
    <TextInputComponent
            isDate
            onChangeText={(e) => {}}
            value={{}}
            amountWidth={{width: layoutType == 'phone'? "100%":"30%"}}
            title={'Start Date'}
          />
             <TextInputComponent
            isDate
            onChangeText={(e) => {}}
            value={{}}
            amountWidth={{width: layoutType == 'phone'? "100%":"30%"}}
            title={'End Date'}
          />
          <View style ={{width : layoutType=='phone'? '100%': '30%'}}>
                  <DropDownPicker
              items={properties}
              title={'Property'}
             
              onChangeItem={(e) => _handleChange({ value: e.value, label: 'property_id' })}
            />
            </View>
</View>
           {/* <DropDownPicker
              items={"hi"}
              title={'Property'}
              defaultValue={"hi"}
              onChangeItem={(e) => {}}
            /> */}
              
    
                 <View style={{ alignItems: 'center', flexDirection: layoutType == 'phone' ? 'column' : 'row', justifyContent: 'space-between' }}>
                 <View style= {{width : layoutType== 'phone'?'100%':'49%'}}>
               <TextInputComponent
            
            onChangeText={(e) => {}}
            value={{}}
            title={'Tenant Name'}
          />
</View>
<View style= {{width : layoutType== 'phone'?'100%':'49%'}}>
<TextInputComponent
        
            onChangeText={(e) => {}}
            value={{}}
            title={'Email'}
          />
          </View>
          </View>
          </Box>
    
        
{/*        
          <Button
        
            childText={'Save'}
            width={150}
            style={{ alignSelf: 'flex-end', marginVertical: 30 }}
          /> */}
   
    </>
  );
};
export default drawerHoc({
  Component: ProvidingNotice,
  showBackButton: true,
});
