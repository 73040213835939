import React, { useState, useEffect } from 'react';
import { TouchableOpacity, View, Image } from 'react-native';
import ajax from '../../../helpers/ajaxHelper';
import Urls from '../../../services/Urls';
import { useNavigation, useRoute } from '@react-navigation/native';
import Box from '../../../components/box';
import {
  Row,
  Right,
  Left,
  Center,
  BoxContainer,
  ImageContainer,
  CircleIcon,
  InfoSection,
  RemainderSection,
  SectionLine,
  Iconcontain,
} from '../../../components/pageHeader/pageHeader.style';
import { Entypo, MaterialCommunityIcons } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import Colors from '../../../constants/Colors';
import Knob from '../../../components/knob';
import Button from '../../../components/button/Button';
import Text from '../../../components/text';
import getPlatformType from '../../../helpers/helpers';
import PopUp from '../../../components/popUp/PopupView';
import { showSuccesToast } from '../../../screens/tenants/tenantOnboarding/helpers';
var layoutType = getPlatformType();

const OffboardHeader = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { property_name, property_worth, id, percentage, address1, city, postcode, onContinuePress, houseName, continueText } = props;
  const navigation = useNavigation();
  const { navigate } = navigation;
  const route = useRoute();

  const onArchiveProperty = () => {
    ajax
      .post(Urls.ARCHIVE_PROPERTY + route?.params?.id)
      .then(({ data }) => {
        if (data.status) {
          // alert(data?.data)
          showSuccesToast(data.data);
          navigation.navigate('Properties');
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };


  return (
    <Box style={{ marginTop: 10 }}>
      <View style={{ flexDirection: layoutType == 'phone' ? 'column' : 'row' }}>
        <View style={{ flexDirection: 'coloumn', width: '80%' }}>
          <BoxContainer>
            <Left>
              <ImageContainer>
              
                <FontAwesome name="user" size={65} color={'#6D08C0'} />
              </ImageContainer>
            </Left>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <View style={{ flexDirection: 'row', alignSelf: 'flex-start' }}>
                <Text
                  fontSize={layoutType == 'phone' ? 16 : 18}
                  marginLeft={layoutType == 'phone' ? 0 : 20}
                  fontWeight={'600'}
                  marginBottom={5}
                  style={{width:'100%'}}
                 numberOfLines={2}
                >
                  {property_name.substring(0,20)}{' '}
                </Text>

    
              </View>
              <View style={{ alignSelf: 'flex-start', marginBottom: 10 }}>
                <Text  numberOfLines={2} marginTop={10} fontSize={15} marginLeft={layoutType == 'phone' ? 0 : 20}>
                  07798765463 {'\n'}
                  bertrand.debeuf@pocketapp.co.uk

                </Text>
              </View>
            </View>

          </BoxContainer>
  
        </View>


        <SectionLine />
        <View style={{ flexDirection: layoutType == 'phone' ? 'row' : 'column', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
          <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
            <Knob
              size={90}
              strokeWidth={10}
              progressPercent={percentage ? Math.round(percentage) : 0}
              text={'Completed'}
              textColor={Colors.light.purple}
              pgColor={Colors.light.purple}
            />
          </View>
          <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
       
          </View>
        </View>
      </View>
    </Box>
  );
};

export default OffboardHeader;
