import React, { useState, useEffect } from 'react';
import { View, FlatList } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import StatusBar from '../../components/statusBar';
import Box from '../../components/box';
import { FontAwesome } from '@expo/vector-icons';
import Text from '../../components/text';
// import DrawerMenu from '../drawerMenu/DrawerMenu';
import { MaterialIcons } from '@expo/vector-icons';
import getPlatformType from '../../helpers/helpers';
import { Container, ContainerScrollView, TextInputWrapper, Row, HorizontalLine, RowCol } from './index.style';
import CalenderComponent from '../../components/calendar';
import CalendarList from '../../components/calendarList';
import { HOME } from './../../constants/svg';
import Button from '../../components/button/Button';
import SearchCardComponent from '../../components/searchCard';
import PropertyTable from '../../components/propertyTable';
import DrawerMenu from '../drawerMenu/DrawerMenu';
import ListCard from '../../components/listCard';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import ItemCounter from '../../components/itemCounter';
import RadioButton from '../../components/radioButton';
import Urls from '../../services/Urls';
import { useRoute } from '@react-navigation/native';
import ajax from '../../helpers/ajaxHelper';
import MarkAsComplete from '../../components/markAsComplete/MarkAsComplete';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import { EmailValidation } from './validation';
import { MenuItem, Select } from '@material-ui/core';
import { showErrorToast, showSuccesToast } from '../tenants/tenantOnboarding/helpers';
import FilePicker from '../../components/filePicker/filepicker.web';
import DocumentView from '../../components/documentViewer';
import moment from 'moment';
import { navigate } from '../../navigation/RootNavigation';
var layoutType = getPlatformType();

const financeDetails = ({ navigation }) => {
  const [state, setState] = useState({
    insurance_type: '',
    insurance_expiry_date: '',
    insurance_premium_amount: '',
    insurance_company_name: '',
    insurance_policy_number: '',
    insurance_company_website: '',
    insurance_company_telephone: '',
    insurance_company_email: '',
    lender_name: '',
    mortgage_account_number: '',
    lender_website: '',
    lender_contact_number: '',
    lender_email: '',
    mortgage_monthly_amount: '',
    mortgage_discount_rate: '',
    mortgage_discount_rate_ends: '',
    mortgage_variable_rate: '',
    mortgage_variable_rate_ends: '',
    mortgage_outstanding_amount: '',
    id: '',
    file_name: '',
    property_insurance_documents: [],
    lender_info_documents: [],
    mortgage_finance_documents: [],
  });
  const [emailError, setEmailError] = useState('');
  const [toggleForInsurance, setToggleForInsurance] = useState();
  const [toggleForLender, setToggleForLender] = useState();
  const [toggleForMortgage, setToggleForMortgage] = useState();
  const route = useRoute();
  const focused = useIsFocused();

  const _handleChange = (e) => {
    console.log('e_data', e);
    setState({ ...state, [e.label]: e?.value });
  };

  const _getPropertyFinanceDetails = (id) => {
    ajax
      .get(Urls.Finance + '/' + id)
      .then(({ data }) => {
        if (data.status) {
          setState({
            ...state,
            ...{
              lender_name: data?.payload?.lender_name,
              mortgage_account_number: data?.payload?.mortgage_account_number,
              lender_website: data?.payload?.lender_website,
              lender_contact_number: data?.payload?.lender_contact_number,
              lender_email: data?.payload?.lender_email,
              mortgage_monthly_amount: data?.payload?.mortgage_monthly_amount,
              mortgage_outstanding_amount: data?.payload?.mortgage_outstanding_amount,
              mortgage_discount_rate: data?.payload?.mortgage_discount_rate,
              mortgage_variable_rate: data?.payload?.mortgage_variable_rate,
              mortgage_variable_rate_ends: data?.payload?.mortgage_variable_rate_ends,
              mortgage_discount_rate_ends: data?.payload?.mortgage_discount_rate_ends,
              insurance_type: data?.payload?.insurance_type,
              insurance_expiry_date: data?.payload?.insurance_expiry_date,
              insurance_premium_amount: data?.payload?.insurance_premium_amount,
              insurance_company_name: data?.payload?.insurance_company_name,
              insurance_policy_number: data?.payload?.insurance_policy_number,
              insurance_company_website: data?.payload?.insurance_company_website,
              insurance_company_telephone: data?.payload?.insurance_company_telephone,
              insurance_company_email: data?.payload?.insurance_company_email,
              property_insurance_documents: data?.payload?.property_insurance_documents,
              lender_info_documents: data?.payload?.lender_info_documents,
              mortgage_finance_documents : data?.payload?.mortgage_finance_documents,
            },
          });
          setToggleForInsurance(data.payload?.is_property_insurance_available ? 'Yes' : 'No');
          setToggleForLender(data.payload?.is_mortgage_info_available ? 'Yes' : 'No');
          setToggleForMortgage(data.payload?.is_mortgage_finance_available ? 'Yes' : 'No');
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  useEffect(() => {
    if (route?.params?.id) {
      _getPropertyFinanceDetails(route?.params?.id);
    }
  }, [focused]);

  const _validate = () => {
    if (state?.lender_email) {
      let EmailValidate = EmailValidation(state?.lender_email);

      if (EmailValidate.status) {
        _onSave();
      } else {
        setEmailError(EmailValidate?.status ? '' : EmailValidate.msg);
      }
    } else {
      _onSave();
    }
  };

  const _onSave = () => {

    const requestBody = {
      lender_name: state?.lender_name ? state?.lender_name : null,
      mortgage_account_number: state?.mortgage_account_number ? state?.mortgage_account_number : null,
      lender_website: state?.lender_website ? state?.lender_website : null,
      lender_contact_number: state?.lender_contact_number ? state?.lender_contact_number : null,
      lender_email: state?.lender_email ? state?.lender_email : null,
      mortgage_monthly_amount: state?.mortgage_monthly_amount ? state?.mortgage_monthly_amount : null,
      mortgage_outstanding_amount: state?.mortgage_outstanding_amount ? state?.mortgage_outstanding_amount : null,
      mortgage_discount_rate: state?.mortgage_discount_rate ? state?.mortgage_discount_rate : null,
      mortgage_variable_rate: state?.mortgage_variable_rate ? state?.mortgage_variable_rate : null,
      mortgage_variable_rate_ends: state?.mortgage_variable_rate_ends ? state?.mortgage_variable_rate_ends : null,
      mortgage_discount_rate_ends: state?.mortgage_discount_rate_ends ? state?.mortgage_discount_rate_ends : moment().format('MM/DD/YYYY'),
      insurance_type: state.insurance_type || null,
      insurance_expiry_date: state.insurance_expiry_date || null,
      insurance_premium_amount: state.insurance_premium_amount || null,
      insurance_company_name: state.insurance_company_name || null,
      insurance_policy_number: state.insurance_policy_number || null,
      insurance_company_website: state.insurance_company_website || null,
      insurance_company_telephone: state.insurance_company_telephone || null,
      insurance_company_email: state.insurance_company_email || null,
      is_property_insurance_available: toggleForInsurance && toggleForInsurance == 'Yes' ? true : false,
      is_mortgage_info_available: toggleForLender && toggleForLender == 'Yes' ? true : false,
      is_mortgage_finance_available: toggleForMortgage && toggleForMortgage == 'Yes' ? true : false,
      file_name: state?.file_name || null,
      property_insurance_documents: state?.property_insurance_documents||null ,
      lender_info_documents: state?.lender_info_documents || null ,
      mortgage_finance_documents: state?.mortgage_finance_documents || null,
    };

    ajax
      .post(`${Urls.Finance}/${route?.params?.id}`, requestBody)
      .then(({ data }) => {
        if (data.status) {
          // showSuccesToast(data?.data);
          if (route?.params?.id) {
            navigation.navigate('PropertySection', { id: route?.params?.id });
          } else {
            navigation.navigate('PropertySection', { id: data?.payload?.id });
          }
        } else {
          // alert(data.message);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const _onAddCertifcate = (value, tag) => {
    let obj = {
      document_url: value[0]?.openbrixName,
      document_name: value[0]?.name,
    };
    console.log('Obj', obj);
    setState({ ...state, [tag]: [...state[tag], obj] });

  };

  const moveToDocs =(data)=>{
    ajax
    .post(Urls.MoveToDocs + '/' + route?.params?.id, data)
    .then(({ data }) => {
      if (data.status) {
        showSuccesToast('Document moved to myDocs section!');
        // _getCertifactes(route?.params?.id);
      } else {
        showErrorToast('Something went wrong!');
      }
    })
    .catch((error) => {
      showErrorToast('Something went wrong!');
    });
  }
  const _onRemove = (obj, tag, i,ismoveToDocs) => {
    let arr = state[tag];
    if(ismoveToDocs){
    // moveToDocs(arr[i])
    arr.splice(i, 1);
    setState({ ...state, [tag]: arr });
    }else{
      arr[i].is_deleted = true;
      setState({ ...state, [tag]: arr });
      // _onSave(false);
    }
   
  };

  return (
    <>
      <ListCard
        localUrlImage={require('./../../assets/images/finance.png')}
        title={'Finance'}
        description={'Please add the financial information relating to this property.'}
        showPropertyCard={route.params?.name}
      />
      <MarkAsComplete
        label={'Insurance Information'}
        flowID={route?.params?.id}
        flowtype={route?.params?.key}
        isComplete={route?.params?.status === 2 ? true : false}
      />

      <Box>
        <RadioButton
          style={{ margin: 'auto' }}
          value={toggleForInsurance}
          options={['Yes', 'No']}
          onChange={(e) => {
            setToggleForInsurance(e);
          }}
          title={'Add Properties Insurance Info?'}
        />
        <If condition={toggleForInsurance == 'Yes'}>

        <Button type={'solid'} childText={'Get Quote'}   onPress={() => {
              navigation.navigate('financeInsuranceQuote')
            }}width={150} />


          <Select
            value={state.insurance_type}
            className="form-control"
            onChange={(e) => {
              console.log('e', e);
              _handleChange({ value: e.target.value, label: 'insurance_type' })
            }}
            style={{ width: '100%', height: 42, marginBottom: 6 }}
            variant="outlined"
            name="insurance_type"
          >
            <MenuItem value={''}>Select Insurance type</MenuItem>
            <MenuItem value={'Building Only'}>Building Only</MenuItem>
            <MenuItem value={'Contents Only'}>Contents Only</MenuItem>
            <MenuItem value={'Building & Contents'}>Building & Contents</MenuItem>
            <MenuItem value={'Other'}>Other</MenuItem>
          </Select>
          <TextInputComponent
            isDate
            onChangeText={(e) => _handleChange({ value: e, label: 'insurance_expiry_date' })}
            date={state.insurance_expiry_date}
            title={'Expiry Date'}
          />
          <TextInputComponent
            isAmount
            onChangeText={(e) => _handleChange({ value: e, label: 'insurance_premium_amount' })}
            value={state.insurance_premium_amount}
            title={'Premium Amount (Total)'}
          />
          <TextInputComponent
            onChangeText={(e) => _handleChange({ value: e, label: 'insurance_company_name' })}
            value={state.insurance_company_name}
            title={'Company Name'}
          />
          <TextInputComponent
            onChangeText={(e) => _handleChange({ value: e, label: 'insurance_policy_number' })}
            value={state.insurance_policy_number}
            title={'Policy Number'}
          />
          <TextInputComponent
            onChangeText={(e) => _handleChange({ value: e, label: 'insurance_company_website' })}
            value={state.insurance_company_website}
            title={'Company Website'}
          />
          <TextInputComponent
            isNumeric
            onChangeText={(e) => _handleChange({ value: e, label: 'insurance_company_telephone' })}
            value={state.insurance_company_telephone}
            title={'Telephone'}
          />
          <TextInputComponent
            error={emailError}
            onChangeText={(e) => _handleChange({ value: e, label: 'insurance_company_email' })}
            value={state.insurance_company_email}
            title={'Email'}
          />
                  <View style={{ marginTop: 10 , marginBottom:15 }}>
                <Text fontSize={15} bold marginBottom={10}>
                  Upload Documents
                </Text>
                <FilePicker
                  style={{ marginBottom: 15, justifyContent: 'space-between' }}
                  onDataSuccess={(data) => {
                    _onAddCertifcate(data, 'property_insurance_documents')}
                  }
                  // onDelete={(index) => {
                  //   let deteledArray = state?.property_insurance_documents;
                  //   deteledArray.splice(index, 1);
    
                  //   setState({ ...state, property_insurance_documents: deteledArray });
                  // }}
                  // files={state?.property_insurance_documents}
                />
              </View>
              {state?.property_insurance_documents &&
              state?.property_insurance_documents.map((e, i) => {
                if (e?.is_deleted) {
                  return null;
                } else {
                  return (
                    <DocumentView
                      title={e?.document_name}
                      url={e?.document_url}
                      directDelete
                      onDelete={(moveToDocs) => _onRemove(e, 'property_insurance_documents', i,moveToDocs)}
                    />
                  );
                }
              })}
        </If>
      </Box>

      <Box style={{ marginTop: 20 }}>
        <RadioButton
          style={{ margin: 'auto' }}
          value={toggleForLender}
          options={['Yes', 'No']}
          onChange={(e) => {
            setToggleForLender(e);
          }}
          title={'Add Lender Info?'}
        />
        <If condition={toggleForLender == 'Yes'}>
          <TextInputComponent
            style={{ marginTop: 30 }}
            onChangeText={(e) => _handleChange({ value: e, label: 'lender_name' })}
            value={state.lender_name}
            title={'Lender Name'}
          />
          <TextInputComponent
            onChangeText={(e) => _handleChange({ value: e, label: 'mortgage_account_number' })}
            value={state.mortgage_account_number}
            title={'Mortgage Account Number'}
          />
          <TextInputComponent
            onChangeText={(e) => _handleChange({ value: e, label: 'lender_website' })}
            value={state.lender_website}
            title={'Lender Website'}
          />
          <TextInputComponent
            isNumeric
            onChangeText={(e) => _handleChange({ value: e, label: 'lender_contact_number' })}
            value={state.lender_contact_number}
            title={'Lender Contact Number'}
          />
          <TextInputComponent
            error={emailError}
            onChangeText={(e) => _handleChange({ value: e, label: 'lender_email' })}
            value={state.lender_email}
            title={'Lender Email'}
          />
                  <View style={{ marginTop: 10 , marginBottom:15 }}>
                <Text fontSize={15} bold marginBottom={10}>
                  Upload Documents
                </Text>
                <FilePicker
                  style={{ marginBottom: 15, justifyContent: 'space-between' }}
                  onDataSuccess={(data) => {
                    _onAddCertifcate(data, 'lender_info_documents')}
                  }
                />
              </View>
              {state?.lender_info_documents&&
              state?.lender_info_documents.map((e, i) => {
                if (e?.is_deleted) {
                  return null;
                } else {
                  return (
                    <DocumentView
                      title={e?.document_name}
                      url={e?.document_url}
                      directDelete
                      onDelete={(moveToDocs) => _onRemove(e, 'lender_info_documents', i,moveToDocs)}
                    />
                  );
                }
              })}
        </If>
      </Box>
      <Box style={{ marginTop: 30 }}>
        <RadioButton
          style={{ margin: 'auto' }}
          value={toggleForMortgage}
          options={['Yes', 'No']}
          onChange={(e) => {
            setToggleForMortgage(e);
          }}
          title={'Add Mortgage Finance Info?'}
        />
        <If condition={toggleForMortgage == 'Yes'}>
          <TextInputComponent
            style={{ marginTop: 30 }}
            isAmount
            onChangeText={(e) => _handleChange({ value: e, label: 'mortgage_monthly_amount' })}
            value={state.mortgage_monthly_amount}
            title={'Monthly Amount'}
          />
          <TextInputComponent
            onChangeText={(e) => _handleChange({ value: e, label: 'mortgage_discount_rate' })}
            value={state.mortgage_discount_rate}
            allowDecimal
            title={'Discount Rate % (Mortgage)'}
          />
          <TextInputComponent
            onChangeText={(e) => _handleChange({ value: e, label: 'mortgage_discount_rate_ends' })}
            title={'Discount Rate Ends'}
            isDate
            date={state?.mortgage_discount_rate_ends}
          />
          <TextInputComponent
            onChangeText={(e) => _handleChange({ value: e, label: 'mortgage_variable_rate' })}
            value={state.mortgage_variable_rate}
            allowDecimal
            title={'Variable Rate % (Mortgage)'}
          />
          <TextInputComponent
            onChangeText={(e) => _handleChange({ value: e, label: 'mortgage_variable_rate_ends' })}
            title={'Variable Rate Ends'}
            isDate
            date={state?.mortgage_variable_rate_ends}
          />
          <TextInputComponent
            isAmount
            onChangeText={(e) => _handleChange({ value: e, label: 'mortgage_outstanding_amount' })}
            title={'Outstanding Mortgage Amount'}
            value={state?.mortgage_outstanding_amount}
          />
                           <View style={{ marginTop: 10 , marginBottom:15 }}>
                <Text fontSize={15} bold marginBottom={10}>
                  Upload Documents
                </Text>
                <FilePicker
                  style={{ marginBottom: 15, justifyContent: 'space-between' }}
                  onDataSuccess={(data) => {
                    _onAddCertifcate(data, 'mortgage_finance_documents')}
                  }
                />
              </View>
              {state?.mortgage_finance_documents&&
              state?.mortgage_finance_documents.map((e, i) => {
                if (e?.is_deleted) {
                  return null;
                } else {
                  return (
                    <DocumentView
                      title={e?.document_name}
                      url={e?.document_url}
                      directDelete
                      onDelete={(moveToDocs) => _onRemove(e, 'mortgage_finance_documents', i,moveToDocs)}
                    />
                  );
                }
              })}
        </If>
      </Box>

      <Button
        onPress={() => _validate()}
        childText={'Save and Continue'}
        width={150}
        style={{ alignSelf: 'flex-end', marginVertical: 30 }}
      />
    </>
  );
};

export default drawerHoc({
  Component: financeDetails,
  showBackButton: true,
});
