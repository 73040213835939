import React, { useState, useEffect } from 'react';
import { View, Text, Image } from 'react-native';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import { BackButton } from '../../components/button/Button';
import { navigate } from '../../navigation/RootNavigation';
import MediaComponent from './mediacomponent';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import RenderHtml from 'react-native-render-html';
import { MediaLink } from './styles';
import getPlatformType from '../../helpers/helpers';
import {
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share";
const layoutType = getPlatformType();

const LandlordMediaDetails = ({ route }) => {
  const [mediaItem, setMediaItem] = useState(null);
  

  useEffect(() => {
    const { data } = route.params;

    ajax
      .get(Urls.GET_POST_DETAILS + data)
      .then(({ data }) => {
        if (data.status) {
          setMediaItem(data.payload);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);


  if (!mediaItem) {
    return <Text>Loading...</Text>;
  }
  const url = `https://dev.hubita.co.uk/LandlordMediaDetails?data=${mediaItem.id}`;
  console.log(url);
  return (
    <View style={{ justifyContent: 'flex-start' , backgroundColor: 'white' ,marginTop:20}}>
      <View style={{ marginLeft: 30  , marginRight:30}}>

      {/* <BackButton onPress={() => navigate('MediaHub')} /> */}
      <Text style={{
        fontFamily: 'Raj',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 40,

        lineHeight: 32,
        marginTop: 20,
        marginBottom: 35
      }}>{mediaItem.title}</Text>

<View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: layoutType == "phone" ? 10 : 0,
            marginBottom: 40,
          }}
        >
        <FacebookShareButton url={url}>
            <Image
              source={require("../../assets/images/fb.png")}
              style={{
                height: 28,
                width: 28,
                resizeMode: "contain",
              }}
            />
          </FacebookShareButton>
          <TwitterShareButton url={url}>
            <Image
              source={require("../../assets/images/x.png")}
              style={{
                height: 28,
                width: 28,
                resizeMode: "contain",
                marginLeft: 15,
              }}
            />
          </TwitterShareButton>
          <LinkedinShareButton url={url}>
          <Image
              source={require("../../assets/images/linkdin.png")}
              style={{
                height: 28,
                width: 28,
                resizeMode: "contain",
                marginLeft: 15,
              }}
            />
          </LinkedinShareButton>
          <RedditShareButton url={url}>
            <Image
              source={require("../../assets/images/reddit.png")}
              style={{
                height: 28,
                width: 28,
                resizeMode: "contain",
                marginLeft: 15,
              }}
            />
          </RedditShareButton>
          {/* <CopyToClipboard text={url} onCopy={(e) => alert("Copied")}>
            <Image
              source={require("../../assets/images/copy.png")}
              style={{
                height: 28,
                width: 28,
                resizeMode: "contain",
                marginLeft: 15,
              }}
            />
          </CopyToClipboard> */}
        </View>

{mediaItem.file ?  <Image source={{ uri: mediaItem.file }} style={{ width: "100%", height: 348 ,resizeMode: "auto" ,marginBottom:30 }} />:null}

 
       <RenderHtml contentWidth={'70%'} source={{ html: `${mediaItem?.description}` }} />
      {mediaItem.link? <MediaLink>{mediaItem.link}</MediaLink>

      :null}

    </View>
    </View>
  );
};

export default drawerHoc({
  Component: LandlordMediaDetails,
  showBackButton: true,
});
