import React, { useState, useEffect } from 'react';
import { View, FlatList } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import Button from '../../components/button/Button';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import Text from '../../components/text';
import Box from '../../components/box';
import ListCard from '../../components/listCard';


const financeInsuranceQuote = ({ navigation }) =>{
return(<>
   <ListCard
        localUrlImage={require('./../../assets/images/finance.png')}
        title={'Finance'}
        description={'Please add the financial information relating to this property.'}
      
      />
<Box style={{ marginTop: 30 }} >
<View style={{ justifyContent: 'center', alignItems: 'center' }}>
  <Text fontSize={20} bold marginBottom={20}>
    Property Insurance
  </Text>
</View>

<TextInputComponent
                onChangeText={{}}
                title={'Insurance Type'}
              
              ></TextInputComponent>
              <TextInputComponent
                onChangeText={{}}
                title={'Insurance Start Date'}
                isDate
              ></TextInputComponent>
              <TextInputComponent
                onChangeText={{}}
                title={'Previous Primum Amount(Total)'}
              
              ></TextInputComponent>
              <TextInputComponent
                onChangeText={{}}
                title={'Previous Insurance Company Name'}
              
              ></TextInputComponent>

<View style={{ justifyContent: 'center', alignItems: 'center' }}>
  <Text fontSize={20} bold marginBottom={15}>
    Personal Information
  </Text>
</View>
              <TextInputComponent
                onChangeText={{}}
                title={'Landlord Name'}
              
              ></TextInputComponent>
              <TextInputComponent
                onChangeText={{}}
                title={'Landlord Correspondence Address'}
              
              ></TextInputComponent>
              <TextInputComponent
                onChangeText={{}}
                title={'Phone'}
              
              ></TextInputComponent>
              <TextInputComponent
                onChangeText={{}}
                title={'Email'}
              
              ></TextInputComponent>
              <TextInputComponent
                onChangeText={{}}
                title={'Date of Birth'}
              
              ></TextInputComponent>
              <TextInputComponent
                onChangeText={{}}
                title={'Property Address'}
              
              ></TextInputComponent>
<TextInputComponent
                onChangeText={{}}
                title={'Property Post Code'}
                
              
              ></TextInputComponent>
                <Button type={'solid'} childText={'Request Quote'}   onPress={{}}width={150}    style={{ alignSelf: 'center' }} />
                </Box>

</>)
};
export default drawerHoc({
    Component: financeInsuranceQuote,
    showBackButton: true,
  });